<script>
import ConfirmationModalView from './Desktop';
import ConfirmationModalControllerMixin from '@/mixins/ConfirmationModalControllerMixin';
import DisplayMixin from '@/mixins/DisplayMixin';
import { ConfirmationModalConstants } from '@/store/confirmation-modal-store-constants';

const primaryLabel = {
  text: 'Yes',
  deleteComment: 'Yes',
  deleteTicket: 'Delete',
  deleteForm: 'Delete',
  deleteFormFlowContent: 'Delete',
};

const secondaryLabel = {
  text: 'No',
  deleteForm: 'Cancel',
  deleteFormFlowContent: 'Cancel',
  exportFormFlowContent: 'Cancel',
};

const content = {
  deleteTicket: defineAsyncComponent(() =>
    import(
      '~/features/tickets/components/modals/confirmations/DeleteTicketConfirmationModal'
    ),
  ),
  deleteFormFlowContent: defineAsyncComponent(() =>
    import(
      '~/features/form-flows/components/modals/confirmations/FormFlowItemConfirmationModal'
    ),
  ),
  exportFormFlowContent: defineAsyncComponent(() =>
    import(
      '~/features/form-flows/components/modals/confirmations/FormFlowItemConfirmationModal.vue'
    ),
  ),
};

export default defineNuxtComponent({
  name: 'ConfirmationModal',
  components: { ConfirmationModalView },
  mixins: [ConfirmationModalControllerMixin, DisplayMixin],
  props: {
    confirmationTitle: {
      type: String,
      required: false,
      default: 'Confirmation title',
    },
    confirmationMessage: {
      type: String,
      required: false,
      default: '',
    },
    confirmationData: {
      type: Object,
      required: false,
      default: () => {},
    },
    confirmationCallbacks: {
      type: Array,
      required: false,
      default: () => [],
    },
    confirmationExtraButtons: {
      type: Array,
      required: false,
      default: () => [],
    },
    confirmationType: {
      type: String,
      required: false,
      validator: (value) => {
        return (
          [
            'text',
            'deleteTicket',
            'deleteFormFlowContent',
            'exportFormFlowContent',
          ].includes(value) !== -1
        );
      },
      default: 'text',
    },
  },
  data() {
    return {
      content,
    };
  },
  methods: {
    getCallback(type) {
      const callback = this.confirmationCallbacks.find(
        callback => callback.type === type,
      );
      if (callback)
        return callback;

      return {};
    },
    hide() {
      this.setConfirmationModalOpened(false);
    },
    onClose() {
      this.hide();
      this.$emit('onClose');
    },
    getPrimaryButtonLabel() {
      if (!primaryLabel[this.confirmationType])
        return 'Yes';

      return primaryLabel[this.confirmationType];
    },
    getSecondaryButtonLabel() {
      if (!secondaryLabel[this.confirmationType])
        return 'No';

      return secondaryLabel[this.confirmationType];
    },
    onCustomEventAction(action, data) {
      const actionCallback = this.getCallback(action);
      if (actionCallback && actionCallback.type)
        actionCallback.callback(data);
    },
    onPrimaryButtonAction() {
      const primaryButtonAction = this.getCallback(
        ConfirmationModalConstants.PRIMARY_BUTTON_ACTION_TYPE,
      );
      if (
        primaryButtonAction.type
        && primaryButtonAction.type
        === ConfirmationModalConstants.PRIMARY_BUTTON_ACTION_TYPE
      )
        primaryButtonAction.callback();
    },
    onSecondaryButtonAction() {
      const secondaryButtonAction = this.getCallback(
        ConfirmationModalConstants.SECONDARY_BUTTON_ACTION_TYPE,
      );
      if (
        secondaryButtonAction.type
        && secondaryButtonAction.type
        === ConfirmationModalConstants.SECONDARY_BUTTON_ACTION_TYPE
      )
        secondaryButtonAction.callback();
    },
  },
});
</script>

<template>
  <div
    class="fixed left-0 top-0 z-[var(--tb-index-modal)] table h-full w-full bg-neutral-1-500 bg-opacity-50 px-0"
  >
    <ConfirmationModalView
      :content="content"
      :confirmation-type="confirmationType"
      :confirmation-data="confirmationData"
      :confirmation-message="confirmationMessage"
      :confirmation-title="confirmationTitle"
      :extra-buttons="confirmationExtraButtons"
      :primary-button-label="getPrimaryButtonLabel()"
      :secondary-button-label="getSecondaryButtonLabel()"
      @on-primary-button-action="onPrimaryButtonAction"
      @on-secondary-button-action="onSecondaryButtonAction"
      @on-custom-event-action="onCustomEventAction"
      @on-close="onClose"
    />
  </div>
</template>
