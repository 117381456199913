<script setup lang="ts">
const { state, setModalRef, setComponentRef } = useModal();
</script>

<template>
  <trailblazer-modal
    :ref="setModalRef"
    .before-close="state.options.beforeClose"
    .actions="state.options.actions"
    :close-on-click="state.options.closeOnClick"
    :confirm-button-text="state.options.confirmButtonText"
    :cancel-button-text="state.options.cancelButtonText"
    :open="Boolean(state.component)"
    :title="state.options.title"
    :width="state.options.width"
    @close="state.options.onClose"
  >
    <component
      :is="state.component"
      :ref="setComponentRef"
      v-bind="state.props"
    />
  </trailblazer-modal>
</template>
