<script>
import { get } from 'lodash-es';
import { mapGetters } from 'vuex';
import Hotjar from '@hotjar/browser';
import navigation from '~/lib/navigation';

import SidebarMixin from '@/components/molecules/Sidebar/mixins/SidebarMixin';
import ConfirmationModalControllerMixin from '@/mixins/ConfirmationModalControllerMixin';
import DisplayMixin from '@/mixins/DisplayMixin';
import LocationsControllerMixin from '@/mixins/Locations/LocationsControllerMixin';
import ModalControllerMixin from '@/mixins/ModalControllerMixin';
import { fetchAvatarImage } from '@/utilities/avatar-utilities';
import {
  SHIPMENT_ANNOUNCE_CREATE_MODAL,
  SHIPMENT_RECEIVE_CREATE_MODAL,
} from '~/features/shipment/constants';

import ConfirmationModal from '@/components/Modal/ConfirmationModal';

import { useFetchLocationCache } from '~/api/useFetchLocationCache';
import { useDeleteActiveSessions } from '~/api/useDeleteActiveSessions';
import { useFetchActiveSessions } from '~/api/useFetchActiveSessions';

const layoutTypes = {
  mobile: defineAsyncComponent(() => import('./Mobile')),
  tablet: defineAsyncComponent(() => import('./Tablet')),
  desktop: defineAsyncComponent(() => import('./Desktop')),
  small_desktop: defineAsyncComponent(() => import('./Desktop')),
};

const modalLayouts = {
  modal: defineAsyncComponent(() => import('@/components/Modal')),
  ticketDetailsModal: defineAsyncComponent(() =>
    import('~/features/tickets/components/modals/TicketDetailsModal'),
  ),
  announcementDetailsModal: defineAsyncComponent(() =>
    import(
      '~/features/registrations/components/modals/Announcement/AnnouncementDetailsModal/index.vue'
    ),
  ),
  signInVisitor: defineAsyncComponent(() =>
    import('~/features/registrations/components/modals/SignInVisitor/index.vue'),
  ),
  directSignIn: defineAsyncComponent(() =>
    import('~/features/registrations/components/modals/DirectSignIn/index.vue'),
  ),
  registrationDetailsModal: defineAsyncComponent(() =>
    import(
      '~/features/registrations/components/modals/RegistrationDetailsModal/index.vue'
    ),
  ),
  registrationAnnounce: defineAsyncComponent(() =>
    import(
      '~/features/registrations/components/modals/Announcement/AnnouncementCreateModal/index'
    ),
  ),
  registrationEdit: defineAsyncComponent(() =>
    import(
      '~/features/registrations/components/modals/Announcement/AnnouncementEditModal.vue'
    ),
  ),
  shipmentAnnounceCreate: defineAsyncComponent(() =>
    import(
      '~/features/shipment/components/modals/ShipmentCreateModal/ShipmentAnnounceCreate.vue'
    ),
  ),
  shipmentReceiveCreate: defineAsyncComponent(() =>
    import(
      '~/features/shipment/components/modals/ShipmentCreateModal/ShipmentReceiveCreate.vue'
    ),
  ),
  registeredShipmentDetails: defineAsyncComponent(() =>
    import(
      '~/features/shipment/components/modals/RegisteredShipmentDetailsModal/index.vue'
    ),
  ),
  shipmentDelivery: defineAsyncComponent(() =>
    import(
      '~/features/shipment/components/modals/ShipmentCloseDeliveryModal/index.vue'
    ),
  ),
};

const types = {
  'Sign in visitor': 'registration',
  'Ticket': 'ticket',
  'Security round': 'tasks',
  'Form': 'form',
  'Announce visitor': 'announced',
  'Direct sign in': 'signedin',
  'Sign in': 'signedout',
  'Announce shipment': 'shipmentAnnounce',
  'Receive shipment': 'shipmentReceive',
};

const DEFAULT_NEW_MODAL_TYPE = 'ticketNew';

const createNewOptions = [
  {
    name: 'Ticket',
    icon: 'tickets',
    type: 'ticketNew',
    ability: 'ticket-create',
  },
  {
    name: 'Form',
    icon: 'checkbox-group',
    type: 'forms',
    ability: 'form-flow-item-create',
  },
  {
    name: 'Announce visitor',
    icon: 'profile',
    type: 'registrationAnnounce',
    ability: 'accessrequest-announcement-create',
  },
  {
    name: 'Sign in visitor',
    icon: 'registration',
    type: 'directSignIn',
    ability: 'accessrequest-registration-create',
  },
  {
    name: 'Announce shipment',
    icon: 'shipment',
    type: SHIPMENT_ANNOUNCE_CREATE_MODAL,
    ability: 'shipment-registration-create',
  },
  {
    name: 'Receive shipment',
    icon: 'shipment',
    type: SHIPMENT_RECEIVE_CREATE_MODAL,
    ability: 'shipment-registration-receive',
  },
];

export default defineNuxtComponent({
  setup() {
    const { user } = useAuth();

    const { location, setSelectedLocation } = useSelectedLocation();
    useFetchLocationCache(computed(() => user.value?.defaultLocation?.id || location.value.id));

    const { data: activeSessions } = useFetchActiveSessions();
    const { mutate: deleteSessions } = useDeleteActiveSessions();

    return { setCurrentLocation: setSelectedLocation, activeSessions, deleteSessions, user };
  },
  components: {
    ConfirmationModal,
  },
  mixins: [
    LocationsControllerMixin,
    DisplayMixin,
    ConfirmationModalControllerMixin,
    ModalControllerMixin,
    SidebarMixin,
  ],
  data() {
    return {
      userData: {
        avatarImage: '',
        fullName: '',
      },
      currentModule: '',
      availableLocations: [],
    };
  },
  computed: {
    createNewOptions() {
      return createNewOptions;
    },
    navigation() {
      return navigation.filter(item => this.$auth.can(item.ability));
    },
    ...mapGetters('ModalStore', [
      'modalType',
      'modalTitle',
      'modalData',
      'modalOpened',
      'modalLayout',
      'modalAbilities',
    ]),
    ...mapGetters('ConfirmationModalStore', [
      'confirmationModalOpened',
      'confirmationModalTitle',
      'confirmationModalMessage',
      'confirmationModalData',
      'confirmationModalType',
      'confirmationModalCallbacks',
      'confirmationExtraButtons',
    ]),
    ...mapGetters('SidebarStore', ['selectedNavigationItem']),
    layoutTypeComponent() {
      return layoutTypes[this.getDisplayMode];
    },
    checkedLocation() {
      if (location.value?.id)
        return location.value.id;
      else
        return this.user?.defaultLocation?.id || this.availableLocations?.[0]?.id;
    },
    getModalLayout() {
      return modalLayouts[this.modalLayout];
    },
    getCreateNewOptions() {
      return createNewOptions.filter(item => this.$auth.can(item.ability));
    },
    canCreateNew() {
      return this.getCreateNewOptions.length > 0;
    },
  },
  watch: {
    $route(newRoute) {
      if (this.currentModule === this.currentModuleName())
        return;

      this.currentModule = this.currentModuleName();
      this.setSelectedNavigationItem(
        this.navigation.find(
          element => newRoute.path.includes(element.url) || element.children?.some(child => child.url.includes(newRoute.path)),
        ) || {},
      );
      // this.setSecondSidebarIsCollapsed(
      //   !this.selectedNavigationItem?.children?.length,
      // );
    },
  },
  async created() {
    this.setSelectedNavigationItem(
      this.navigation.find(
        element => this.$route.path.includes(element.url) || element.children?.some(child => child.url.includes(this.$route.path)),
      ) || {},
    );
    this.setSecondSidebarIsCollapsed(
      !this.selectedNavigationItem?.children?.length || true,
    );
    this.currentModule = this.currentModuleName();
    this.availableLocations = await this.getLocationsOnly();
    // TODO: This should be changed with user location currently we receive null.
    if (sessionStorage.getItem('selectedLocation') === '{}' || sessionStorage.getItem('selectedLocation') === null) {
      this.setSelectedLocation(this.user?.defaultLocation || this.availableLocations[0]);
      this.setCurrentLocation(this.user?.defaultLocation || this.availableLocations[0]);
    }
    else {
      this.setSelectedLocation(JSON.parse(sessionStorage.getItem('selectedLocation')));
      this.setCurrentLocation(JSON.parse(sessionStorage.getItem('selectedLocation')));
    }

    this.$eventBus.$on('toast:show', this.showToast);
  },
  unmounted() {
    this.$eventBus.$off('toast:show', this.showToast);
  },
  beforeMount() {
    this.listen();
  },
  beforeUnmount() {
    this.listen('off');
  },
  async mounted() {
    this.getUser();

    if (this.$auth.user.value) {
      const data = new TextEncoder().encode(this.$auth.user.value.email);
      const hashBuffer = await crypto.subtle.digest('SHA-256', data);
      const hashArray = Array.from(new Uint8Array(hashBuffer));

      const hashHex = hashArray
        .map(b => b.toString(16).padStart(2, '0'))
        .join('');

      Hotjar.identify(hashHex);
    }
  },
  methods: {
    listen(status = 'on') {
      this.$eventBus[`$${status}`]('onTabViewClick', this.selectNavigation);
    },
    showToast(options) {
      this.$refs.toastGroup.addToast(options);
    },
    onModalTypeChanged(modalType) {
      this.setModalType(modalType.type);
    },
    selectNavigation(item) {
      const selectedNavItem = this.navigation.find(
        navItem => navItem.label === item.label,
      );
      this.setSelectedNavigationItem(selectedNavItem);
      this.setSecondSidebarIsCollapsed(false);
    },
    currentSelectedNavigationItem() {
      switch (this.$route.name) {
        case 'ticket-status-status': {
          return 'ticket';
        }
        case 'form-flows':
        case 'form-template-edit-status':
        case 'form-template-create-steps':
        case 'form-template-all':
        case 'form-template-active':
        case 'form-template-inactive': {
          return 'form-flow';
        }
        case 'access-request-announced':
        case 'access-request-signedin':
        case 'access-request-signedout': {
          return 'accessrequest';
        }
        case 'reporting': {
          return 'system.reporting';
        }
        case 'shipment-announced':
        case 'shipment-received':
        case 'shipment-delivered': {
          return 'shipment';
        }
        default: {
          return this.$route.name;
        }
      }
    },
    currentModuleName() {
      switch (this.$route.name) {
        case 'ticket':
        case 'ticket-status-status': {
          return 'tickets';
        }
        case 'form-flows': {
          return 'formFlows';
        }
        case 'form-template-all':
        case 'form-template-active':
        case 'form-template-inactive': {
          return 'formTemplate';
        }
        case 'form-template-edit-status':
        case 'form-template-create-steps': {
          return 'formTemplateCreate';
        }
        case 'access-request-announced':
        case 'access-request-signedin':
        case 'access-request-signedout': {
          return 'accessRequest';
        }
        case 'shipment-announced':
        case 'shipment-received':
        case 'shipment-delivered': {
          return 'shipment';
        }
        case 'search': {
          return 'searchResults';
        }
        default: {
          return this.$route.name;
        }
      }
    },
    onLocationChanged(location) {
      this.setSelectedLocation(location);
      this.setCurrentLocation (location);
      sessionStorage.setItem('registrationStorage', { group_id: undefined });
    },
    getAvatarImage() {
      const avatar = get(this.$auth?.user, 'avatar');
      return fetchAvatarImage(avatar);
    },
    userFullname() {
      return this.$auth?.user?.name || '';
    },
    getUser() {
      this.userData = {
        avatarImage: this.getAvatarImage(),
        fullName: this.userFullname(),
      };
    },
    openBigModal() {
      let type = '';

      for (const routeName of this.$route.name.split('-')) {
        if (Object.values(types).includes(routeName)) {
          type = routeName;
          break;
        }
      }

      if (this.$route.path.includes('/shipment/announced'))
        type = SHIPMENT_ANNOUNCE_CREATE_MODAL;
      else if (this.$route.path.includes('/shipment/received'))
        type = SHIPMENT_RECEIVE_CREATE_MODAL;
      else if (type === '')
        type = DEFAULT_NEW_MODAL_TYPE;

      switch (type) {
        case 'ticket': {
          type = 'ticketNew';
          this.$store.commit('CreateTicketStore/clearTicketStore');
          break;
        }
        case 'form': {
          type = 'forms';
          break;
        }
        case 'announced': {
          type = 'registrationAnnounce';
          break;
        }
        case 'signedin': {
          type = 'directSignIn';
          break;
        }
        case 'signedout': {
          type = 'directSignIn';
          break;
        }
        case SHIPMENT_ANNOUNCE_CREATE_MODAL: {
          type = SHIPMENT_ANNOUNCE_CREATE_MODAL;
          break;
        }
        case SHIPMENT_RECEIVE_CREATE_MODAL: {
          type = SHIPMENT_RECEIVE_CREATE_MODAL;
          break;
        }
      }
      this.setModalType(type);
      this.setModalTitle('Create new');
      this.setModalOpened(true);
    },
    closeBigModal() {
      this.setModalOpened(false);
    },
  },
});
</script>

<template>
  <div class="fixed size-full bg-neutral-3">
    <component
      :is="layoutTypeComponent" :can-create-new="canCreateNew" :checked-location="checkedLocation"
      :current-module="currentModule" :locations="availableLocations" :navigation="navigation" :user="userData"
      :active-session-count="activeSessions?.data?.length" @on-create-new="openBigModal"
      @on-location-changed="onLocationChanged"
    />
    <component
      :is="getModalLayout" v-if="modalOpened" :abilities="modalAbilities"
      :create-new-options="getCreateNewOptions" :type-options="getCreateNewOptions" :modal-data="modalData"
      :modal-key="modalType + modalOpened" :modal-type="modalType" @type-changed="onModalTypeChanged"
      @close="closeBigModal"
    />
    <ConfirmationModal
      v-if="confirmationModalOpened" :confirmation-callbacks="confirmationModalCallbacks"
      :confirmation-data="confirmationModalData" :confirmation-extra-buttons="confirmationExtraButtons"
      :confirmation-message="confirmationModalMessage" :confirmation-title="confirmationModalTitle"
      :confirmation-type="confirmationModalType"
    />
    <trailblazer-toast-group ref="toastGroup" @dismiss="e => e.target.remove()" />
  </div>

  <ModalRoot />
</template>
