<script>
import { mapGetters, mapState } from 'vuex';

import DisplayMixin from '@/mixins/DisplayMixin';
import { ConfirmationModalConstants } from '@/store/confirmation-modal-store-constants';

export default defineNuxtComponent({
  name: 'ConfirmationModalView',
  mixins: [DisplayMixin],
  props: {
    confirmationTitle: {
      type: String,
      required: false,
      default: 'Confirmation title',
    },
    confirmationMessage: {
      type: String,
      required: false,
      default: '',
    },
    confirmationData: {
      type: Object,
      required: false,
      default: () => {},
    },
    content: {
      type: Object,
      required: false,
      default: () => {},
    },
    confirmationType: {
      type: String,
      required: false,
      default: 'text',
    },
    primaryButtonLabel: {
      type: String,
      required: false,
      default: 'text',
    },
    secondaryButtonLabel: {
      type: String,
      required: false,
      default: 'text',
    },
    extraButtons: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters('ConfirmationModalStore', ['confirmationModalCallbacks']),
    ...mapGetters('FormFlowsModuleStore', ['processingAction']),
    ...mapState('ContextStore', {
      color: state => state.accentColor,
      darkMode: state => state.darkMode,
    }),
    contentComponent() {
      return this.content[this.confirmationType];
    },
    hasPrimaryButton() {
      return this.confirmationModalCallbacks.some(
        callback =>
          callback.type
          === ConfirmationModalConstants.PRIMARY_BUTTON_ACTION_TYPE,
      );
    },
  },
  methods: {
    onClose() {
      this.$emit('onClose');
    },
    onPrimaryButtonAction() {
      this.$emit('onPrimaryButtonAction');
    },
    onSecondaryButtonAction() {
      this.$emit('onSecondaryButtonAction');
    },
    contentComponentProps() {
      switch (this.confirmationType) {
        case 'deleteTicket': {
          return {
            ticketData: this.confirmationData,
          };
        }
        case 'exportFormFlowContent':
        case 'deleteFormFlowContent': {
          return {
            formFlowItems: this.confirmationData.selectedFormflows,
          };
        }
      }
    },
    contentComponentEvents() {
      switch (this.confirmationType) {
        case 'exportFormFlowContent':
        case 'deleteFormFlowContent': {
          return {
            removeItem: (item) => {
              this.$emit(
                'onCustomEventAction',
                ConfirmationModalConstants.FORMS_FLOW_ITEM_SELECTION_REMOVE_EVENT,
                item,
              );
            },
          };
        }
      }
    },
    isDangerButton() {
      switch (this.confirmationType) {
        case 'deleteTicket':
        case 'deleteComment':
        case 'deleteForm':
        case 'deleteFormFlowContent': {
          return true;
        }
        default: {
          return false;
        }
      }
    },
    getButtonType() {
      switch (this.confirmationType) {
        case 'deleteTicket':
        case 'deleteComment':
        case 'deleteForm':
        case 'deleteFormFlowContent': {
          return 'error';
        }
        default: {
          return 'primary';
        }
      }
    },
  },
});
</script>

<template>
  <trailblazer-confirm-dialog
    :title="__(confirmationTitle)"
    open="true"
    type="default"
    width="55vw"
  >
    <h3 class="mt-5 dark:text-white">
      {{ __(confirmationMessage) }}
    </h3>
    <div v-if="confirmationType !== 'text'" class="mt-7 h-full">
      <component
        :is="contentComponent"
        v-bind="contentComponentProps()"
        v-on="contentComponentEvents()"
      />
    </div>

    <div slot="footer" class="mt-6 flex">
      <div class="w-full">
        <div class="flex justify-end space-x-4">
          <trailblazer-button
            size="m"
            class="w-28 shadow"
            variant="secondary"
            @click="onSecondaryButtonAction()"
          >
            {{ __(secondaryButtonLabel) }}
          </trailblazer-button>
          <trailblazer-button
            v-for="(button, index) in extraButtons"
            :key="index"
            :variant="button.buttonType"
            :disabled="processingAction"
            class="w-32 shadow"
            @click="button.onClick"
          >
            {{ __(button.label) }}
          </trailblazer-button>
          <trailblazer-button
            v-if="hasPrimaryButton"
            :danger="isDangerButton()"
            :disabled="processingAction"
            variant="primary"
            class="w-28 shadow"
            @click="onPrimaryButtonAction()"
          >
            {{ __(primaryButtonLabel) }}
          </trailblazer-button>
        </div>
      </div>
    </div>
  </trailblazer-confirm-dialog>
</template>
