const PRIMARY_BUTTON_ACTION_TYPE = 'onPrimaryButton';
const SECONDARY_BUTTON_ACTION_TYPE = 'onSecondaryButton';

const FORMS_FLOW_ITEM_SELECTION_REMOVE_EVENT = 'onFormFlowSelectionItemRemove';

export const ConfirmationModalConstants = {
  PRIMARY_BUTTON_ACTION_TYPE,
  SECONDARY_BUTTON_ACTION_TYPE,
  FORMS_FLOW_ITEM_SELECTION_REMOVE_EVENT,
};
