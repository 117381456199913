import { useQuery } from '@tanstack/vue-query';
import type { ApiResponse } from '~/types';

interface SessionData {
  id: number;
  ip_address: string;
  last_activity: number;
}

export function useFetchActiveSessions() {
  const { loggedIn } = useAuth();

  return useQuery({
    queryKey: ['activeSessions'],
    queryFn: async () => {
      const { data, error } = await useApi<ApiResponse<SessionData>>(
                `/api/v2/auth/active-sessions`,
      );

      if (error.value)
        throw error.value;

      return data.value;
    },
    enabled: loggedIn,
    refetchInterval: 10000,
    refetchIntervalInBackground: true,
  });
}
